<template>
  <div v-if="packages !== null">
    <section
      class="secction-h tour-section-1 mt-5 mb-5"
      v-if="packages.length > 0"
    >
      <b-container class="bv-section1-row">
        <b-row class="px-3 px-lg-0">
          <h3 v-if="localeTitle == 'en'">
            {{ area.enTitle }}
          </h3>
          <h3 v-if="localeTitle == 'cn'">
            {{ area.cnTitle }}
          </h3>
          <h3 v-if="localeTitle == 'hk'">
            {{ area.hkTitle }}
          </h3>
        </b-row>
        <b-row v-if="area.featuredVideoUrl">
          <video
            width="1140"
            height="640px"
            controls
            :autoplay="area.autoplay==='Y'?true:false"
            :muted="area.autoplay==='Y'?true:false"
            :loop="area.loopFlag==='Y'?true:false"
            v-if="area.featuredVideoUrl"
            class="w-100 px-3 px-lg-0"
          >
            <source :src="oss+area.featuredVideoUrl" />
            {{ $t('header.browser_msg') }}
          </video>
        </b-row>
        <b-row class="mt-4">
          <b-col cols="12" v-if="packages[0]">
            <b-card
              class="card-image mb-3"
              :style="{ backgroundImage: `url(${oss+packages[0].mainPicture})` }"
            >
              <div class="text-white text-center rgba-black-strong py-3 px-2">
                <div>
                  <b-row class="px-3">
                    <b-col cols="5" class="text-left b-item">
                      <div class="b-title">
                        <h5 class="py-2">
                          ({{ packages[0].packageCode }})
                          {{ packages[0].title }}
                        </h5>
                        <h6 class="purple-text">
                          {{ packages[0].shortDescription }}
                        </h6>
                      </div>
                    </b-col>
                    <b-col
                      cols="7"
                      class="text-right align-bottom pb-1"
                      v-if="packages[0].link == 'Y'"
                    >
                      <router-link :to="`/tour/${packages[0].packageCode}`"
                        ><b-button squared variant="outline-secondary">{{
                          $t('View_details')
                        }}</b-button></router-link
                      >
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" v-if="packages[1]">
            <b-card
              class="card-image mb-3"
              :style="{ backgroundImage: `url(${oss+packages[1].mainPicture})` }"
            >
              <div class="text-white text-center rgba-black-strong py-3 px-2">
                <div>
                  <b-row class="px-3">
                    <b-col cols="8" class="text-left b-item">
                      <div class="b-title">
                        <h5 class="py-2">
                          ({{ packages[1].packageCode }})
                          {{ packages[1].title }}
                        </h5>
                        <h6 class="purple-text">
                          {{ packages[1].shortDescription }}
                        </h6>
                      </div>
                    </b-col>
                    <b-col
                      cols="4"
                      class="text-right align-bottom pb-1 b-item"
                      v-if="packages[1].link == 'Y'"
                    >
                      <router-link :to="`/tour/${packages[1].packageCode}`"
                        ><b-button squared variant="outline-secondary">{{
                          $t('View_details')
                        }}</b-button></router-link
                      >
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-card>
          </b-col>
          <b-col cols="12" v-if="packages[2]">
            <b-card
              class="card-image mb-3"
              :style="{ backgroundImage: `url(${oss+packages[2].mainPicture})` }"
            >
              <div class="text-white text-center rgba-black-strong py-3 px-2">
                <div>
                  <b-row class="px-3">
                    <b-col cols="8" class="text-left b-item">
                      <div class="b-title">
                        <h5 class="py-2">
                          ({{ packages[2].packageCode }})
                          {{ packages[2].title }}
                        </h5>
                        <h6 class="purple-text">
                          {{ packages[2].shortDescription }}
                        </h6>
                      </div>
                    </b-col>
                    <b-col
                      cols="4"
                      class="text-right align-bottom pb-1 b-item"
                      v-if="packages[2].link == 'Y'"
                    >
                      <router-link :to="`/tour/${packages[2].packageCode}`"
                        ><b-button squared variant="outline-secondary">{{
                          $t('View_details')
                        }}</b-button></router-link
                      >
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-card>
          </b-col>
          <b-col cols="12" v-if="packages[3]">
            <b-card
              class="card-image mb-3"
              :style="{ backgroundImage: `url(${oss+packages[3].mainPicture})` }"
            >
              <div class="text-white text-center rgba-black-strong py-3 px-2">
                <div>
                  <b-row class="px-3">
                    <b-col cols="8" class="text-left b-item">
                      <div class="b-title">
                        <h5 class="py-2">
                          ({{ packages[3].packageCode }})
                          {{ packages[3].title }}
                        </h5>
                        <h6 class="purple-text">
                          {{ packages[3].shortDescription }}
                        </h6>
                      </div>
                    </b-col>
                    <b-col
                      cols="4"
                      class="text-right align-bottom pb-1 b-item"
                      v-if="packages[3].link == 'Y'"
                    >
                      <router-link :to="`/tour/${packages[3].packageCode}`"
                        ><b-button squared variant="outline-secondary">{{
                          $t('View_details')
                        }}</b-button></router-link
                      >
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center mt-2" v-if="packages.length > 5">
            <a :href="`#/packages/section3`"
              ><b-button
                class="view-more"
                squared
                variant="outline-secondary"
                >{{ $t('More') }}</b-button
              ></a
            >
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>
<script>
import configUrl from '@/global/config.js'
export default {
  name: 'section3',
  data() {
    return {
      area: null,
      loading: true,
      errored: false,
      localeTitle: 'en',
      oss:''
    };
  },
  props: {
    area: {
      type: Array,
    },
  },
  
  computed:{
    packages(){
      return this.area&&this.area.packagesVos||[]
    }
  },
  mounted() {
    this.oss=configUrl.oss_path
    if (localStorage.getItem('lang_frontend') == 'en') {
      this.localeTitle = 'en';
    } else if (localStorage.getItem('lang_frontend') == 'cn') {
      this.localeTitle = 'cn';
    } else {
      this.localeTitle = 'hk';
    }
  },
};
</script>
<style type="text/css">
.tour-section-1 .col-12 {
  padding: 0;
}

.tour-section-1 .col-12 .card {
  min-height: 27rem;
}
</style>
